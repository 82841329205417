'use client';
import {
  ArrowsUpDownIcon,
  BoltIcon,
  ChartBarIcon,
  CubeTransparentIcon,
  GlobeAltIcon,
  QueueListIcon,
  ShieldExclamationIcon,
  SparklesIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';

export const FeaturesGrid = ({ description }: { description?: string }) => {
  const [step, setStep] = useState(0);

  const detectProps = useSpring({
    width: step === 1 ? '100%' : '0%',
  });
  const detectProps2 = useSpring({
    transform: step === 1 ? 'translateX(-2%)' : 'translateX(0%)',
  });

  const step2Props = useSpring({
    // opacity: step === 3 ? 0.3 : 1,
    transform: step === 2 ? 'scale(1.1)' : 'scale(1)',
    color: step === 2 ? '#2563eb' : '#111827',
  });

  const step3props = useSpring({
    transform:
      // 3d rotate animate up
      step === 3
        ? 'rotateX(10deg) rotateY(10deg)'
        : 'rotateX(0deg) rotateY(0deg)',
    color: step === 3 ? '#2563eb' : '#111827',
  });

  useEffect(() => {
    const i = setInterval(() => {
      setStep((s) => (s + 1) % 4);
    }, 1000);

    return () => {
      clearInterval(i);
    };
  }, []);

  return (
    <section className="pb-12 md:pb-24 relative w-full px-4 mx-auto md:px-24 lg:px-8  z-10">
      <div
        className="absolute  right-0 bottom-0 left-0 bg-gradient-to-t from-gray-100 pointer-events-none"
        style={{ height: 150, maxHeight: '50vh' }}
      ></div>
      <div className=" w-full relative flexmd:max-w-6xl mx-auto">
        <div className="pt-12 md:pt-24 flex items-center flex-col">
          <span className=" text-xl font-semibold inline-block rounded-full mb-8  py-1 mx-auto  text-blue-500">
            COMPLETE TOOLKIT
          </span>
          <h2 className="text-3xl md:text-6xl font-bold whitespace-pre  relative mb-0 flex text-gray-900 ">
            {/* @ts-ignore */}
            <animated.span
              className="relative md:mx-1 transform-gpu"
              style={{
                transformOrigin: 'bottom center',
                ...detectProps2,
              }}
            >
              Batteries
              {/* @ts-ignore */}
              <animated.span
                // @ts-ignore
                pointerEvents="none"
                className="absolute text-blue-600 transform-gpu"
                style={{
                  top: 0,
                  left: 0,
                  zIndex: 2,
                  transformOrigin: 'left center',
                  overflow: 'hidden',
                  ...detectProps,
                }}
              >
                <span>Batteries</span>
              </animated.span>
            </animated.span>
            <span>{'  '}</span>
            {/* @ts-ignore */}
            <animated.span
              className="relative md:mx-1 transform-gpu"
              style={step2Props}
            >
              Included
            </animated.span>
            <span>{'  '}</span>
            {/* <animated.span
              className="relative md:mx-1 transform-gpu"
              style={{
                transformOrigin: 'bottom center',
                ...step3props,
              }}
            >
              Features
            </animated.span> */}
          </h2>
        </div>

        <p className="max-w-xl mx-auto text-base text-gray-700 md:text-xl text-center mt-12">
          {description}
        </p>

        <div className="grid grid-cols-2 md:grid-cols-3 max-w-6xl mx-auto mt-12 hide-outer-border ">
          <GridItem>
            <SparklesIcon
              className="h-12 w-12 text-blue-500"
              aria-hidden="true"
            />
            <span className="mt-2">Powered by AI</span>
            <span className="mt-2 font-light">
              Understands complex context and intentions, not just keywords.
            </span>
          </GridItem>
          <GridItem href={'/analytics'}>
            <ChartBarIcon
              className="h-12 w-12 text-blue-500"
              aria-hidden="true"
            />
            <span className="mt-2">Analytics</span>
            <span className="mt-2 font-light">
              Get important insights about your content and users.
            </span>
          </GridItem>
          <GridItem href={'/moderation-dashboard'}>
            <UserIcon className="h-12 w-12 text-blue-500" aria-hidden="true" />
            <span className="mt-2">Self improving</span>
            <span className="mt-2 font-light">
              Use your corrections to improve the AI's accuracy.
            </span>
          </GridItem>

          <GridItem href={'/custom-ai-models'}>
            <CubeTransparentIcon
              className="h-12 w-12 text-blue-500"
              aria-hidden="true"
            />
            <span className="mt-2">Train Custom Models</span>
            <span className="mt-2 font-light">
              Tailor models to your specific use case, and get unparalleled
              accuracy.
            </span>
          </GridItem>

          <GridItem>
            <BoltIcon className="h-12 w-12 text-blue-500" aria-hidden="true" />
            <span className="mt-2">Real-time Analysis</span>
            <span className="mt-2 font-light">
              Prevent harmful content before it reaches your users.
            </span>
          </GridItem>

          <GridItem>
            <GlobeAltIcon
              className="h-12 w-12 text-blue-500"
              aria-hidden="true"
            />
            <span className="mt-2">200+ languages</span>
            <span className="mt-2 font-light">
              We support all major languages for content moderation.
            </span>
          </GridItem>
          <GridItem
            href={'https://docs.moderationapi.com/guides/user-flagged-content'}
          >
            <ShieldExclamationIcon
              className="h-12 w-12 text-blue-500"
              aria-hidden="true"
            />
            <span className="mt-2">Handle user flags</span>
            <span className="mt-2 font-light">
              Allow users to report harmful content and take appropriate
              actions.
            </span>
          </GridItem>
          <GridItem href={'/integrations'}>
            <ArrowsUpDownIcon
              className="h-12 w-12 text-blue-500"
              aria-hidden="true"
            />
            <span className="mt-2 ">Easy Integration</span>
            <span className="mt-2 font-light">
              Integrate with your existing platform in minutes. No coding
              required.
            </span>
          </GridItem>
          <GridItem href={'/moderation-dashboard'}>
            <QueueListIcon
              className="h-12 w-12 text-blue-500"
              aria-hidden="true"
            />
            <span className="mt-2">Get the overview</span>
            <span className="mt-2 font-light">
              Use the dashboard to see what's being flagged and moderate it.
            </span>
          </GridItem>
        </div>
      </div>
    </section>
  );
};

const GridItem = ({
  children,
  full,
  href = '/app',
}: {
  children: React.ReactNode;
  full?: boolean;
  href?: string;
}) => {
  return (
    <Link
      href={href}
      className={
        '  text-center  border-slate-100  border-2  md:col-span-1 md:aspect-w-2 md:aspect-h-1 py-12 group hover:bg-slate-50 ' +
        (full
          ? 'col-span-2 aspect-w-5 aspect-h-2'
          : 'col-span-1 aspect-w-2 aspect-h-1')
      }
    >
      <div className="flex items-center justify-center transform scale-80 xl:transform-none px-6 ">
        <p className="text-base text-center flex flex-col items-center justify-center text-gray-500 md:text-xl font-semibold ">
          {children}
        </p>
      </div>
    </Link>
  );
};
